.system-pointer {
    transition: $transition-default;
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: map-deep-get($colors, "brand", "primary");
    border-radius: 100%;
    cursor: pointer;
    $elm: &;

    &__box {
        transition: $transition-default;
        background: map-deep-get($colors, "white", "default");
        padding: 25px 30px;
        border: 1px solid map-deep-get($colors, "gray", "default");
        box-shadow: 0 12px 14px 0 rgba(map-deep-get($colors, "black", "default"), 0.3);
        opacity: 0;
        z-index: 10;
        pointer-events: none;
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: auto;
            right: auto;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            max-width: 375px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 0 1.1em;
    }

    &__title {
        display: block;
        margin: 0;
        padding: 0;
        color: map-deep-get($colors, "brand", "secondary");
    }

    &__close {
        color: map-deep-get($colors, "black", "default");
        position: relative;
        display: block;
        z-index: 1;
        pointer-events: all;
        width: 29px;
        height: 29px;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &__content {
        padding-bottom: 15px;
    }

    &--open {
        background-color: map-deep-get($colors, "brand", "secondary");

        #{$elm}__box {
            opacity: 1;

            @include media-breakpoint-up(md) {
                bottom: 20px;
            }
        }
    }
}