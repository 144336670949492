.system-form {
    background-color: map-deep-get($colors, "gray", "light");
    padding: calc(5% + 10px) 5% 5%;

    &__wrapper {
        margin-top: 40px;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }

    &__title {
        margin-bottom: -0.5em;
        font-weight: 100;
    }

    .form__group:last-child {
        margin-bottom: 0;
    }
}