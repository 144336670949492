.accordion {
    margin-bottom: 50px;
}

.accordion-card {
    $elm: &;

    &__header {
        background: map-deep-get($colors, "gray", "default");
        border-bottom: 1px solid map-deep-get($colors, "gray", "light");
        line-height: 0;
        margin-bottom: 0;
        position: relative;
    }

    &__link {
        color: map-deep-get($colors, "brand", "secondary");
        text-decoration: none;
        font-size: rem-calc(26);
        padding: 20px 75px 20px 20px;
        line-height: 1;
        display: block;
        font-weight: 300;
    }

    &__content {
        transition: $transition-default;
        background: map-deep-get($colors, "brand", "secondary");
        color: map-deep-get($colors, "white", "default");
        padding: 0 30px;
        overflow: hidden;
        font-weight: 300;
        line-height: 1.5em;
        -webkit-font-smoothing: antialiased;
        max-height: 0;

        @include media-breakpoint-up(md) {
            padding: 0 2%;
        }
    }

    &__icons {
        position: absolute;
        width: 1.3rem;
        height: 1.3rem;
        right: 20px;
        top: 50%;
        margin-top: -.65rem;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__icon {
        transition: $transition-default;

        &--minus {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &--active {
        #{$elm}__content {
            max-height: 1200px;
            padding-top: 30px;
            padding-bottom: 30px;

            @include media-breakpoint-up(md) {
                max-height: 800px;
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }

        #{$elm}__icon--plus {
            opacity: 0;
        }
    }
}
