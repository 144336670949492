.content-slide {
    width: 100%;

    &__inner {
        position: relative;
        width: 100%;
    }

    &__background {
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(89.63deg, rgba(map-deep-get($colors, "brand", "secondary"), 0.4) 0%, map-deep-get($colors, "brand", "secondary") 100%);

            @include media-breakpoint-up(md) {
                background: linear-gradient(89.63deg, rgba(map-deep-get($colors, "brand", "secondary"), 0) 0%, map-deep-get($colors, "brand", "secondary") 100%);
            }
        }
    }

    &__content {
        color: map-deep-get($colors, "white", "default");
        padding-top: 100px;
        padding-bottom: 100px;
        height: 100%;

        //Webkey content
        /* stylelint-disable */
        h3 {
            @extend .h4;
            margin-top: 0;
        }

        p {
            -webkit-font-smoothing: antialiased;
        }
        /* stylelint-enable */
    }
}