.milestones-slider {
    position: relative;
    margin-bottom: 100px;

    &::before {
        content: "";
        background-color: map-deep-get($colors, "gray", "light");
        position: absolute;
        top: 20%;
        left: 0;
        right: 0;
        bottom: 0;

        @include media-breakpoint-up(md) {
            top: 0;
        }
    }

    &__title {
        padding: 30px 0 15px;
        margin-bottom: 0;
        z-index: 1;
        position: relative;

        @include media-breakpoint-up(md) {
            transform: translateY(65%);
            padding: 0;
        }
    }

    &__nav-wrapper {
        display: flex;
        align-items: center;
    }

    &__nav {
        transition: $transition-default;
        display: inline-block;
        padding: 10px;
        border: 1px solid map-deep-get($colors, "gray", "dark");
        margin-right: 5px;
        line-height: 0;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "brand", "primary");
            width: 35px;
            max-height: 20px;
        }

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background: map-deep-get($colors, "brand", "primary");

                svg {
                    fill: map-deep-get($colors, "white", "default");
                }
            }
        }
    }

    &__counter {
        display: inline-block;
    }

    &__active-count {
        color: map-deep-get($colors, "brand", "primary");
    }

    // code vanuit de vendor en js overschrijven om te zorgen voor een fade
    /* stylelint-disable */
    .flickity-slider {
        transform: none !important;
        overflow: visible !important;
    }

    .flickity-viewport {
        overflow: visible !important;
    }
    /* stylelint-enalbe */
}

.flickity-viewport {
    transition: height 0.2s;
}