.content-slider {
    width: 100%;

    &__inner {
        margin-left: -20px;
        margin-right: -20px;
    }

    &__controls {
        margin-left: -5px;
    }

    &__nav {
        transition: $transition-default;
        display: inline-block;
        padding: 10px;
        border: 1px solid map-deep-get($colors, "white", "default");
        margin-left: 5px;
        line-height: 0;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "brand", "primary");
            width: 35px;
            max-height: 20px;
        }

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                border: 1px solid map-deep-get($colors, "brand", "primary");

                svg {
                    fill: map-deep-get($colors, "white", "default");
                }
            }
        }
    }

    /* stylelint-disable */
        &.loaded {
            .content-slide,
            .content-slide__inner {
                height: 100%;
            }
        }
    /* stylelint-enable */
}