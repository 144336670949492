// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    .intro {
        font-size: rem-calc(18);
        margin-top: 0;
        margin-bottom: 1.75em;
    }

    p {
        margin: 0 0 1em;
        font-weight: 500;
        line-height: 1.25em;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 1em 0 1em;
    }

    img {
        max-width: 100%;
    }
    
    a:not(.button){
        color: map-deep-get($colors, "brand", "primary");
        text-decoration: underline;
    }

    ul {
        margin: rem-calc(20) 0;

        li {
            position: relative;
            padding-left: 20px;
            line-height: 1.25em;

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "primary");
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .35em;
            }
        }
    }
}

/* stylelint-enable */