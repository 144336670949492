.team-member {
    width: 100%;
    z-index: 1;
    position: relative;
    $elm: &;

    &--slide {
        width: 90%;

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            width: 33.333%;
        }

        #{$elm} {
            &__inner {
                transition: all $transition-default-smoothing $transition-default-time;
                margin-left: -20px;
                margin-right: -20px;
                padding-bottom: 40px;
            }

            &__title {
                transition: all $transition-default-smoothing $transition-default-time 0s;
                transform: translateY(15%);
                opacity: 0;
            }

            &__description {
                transition: all $transition-default-smoothing $transition-default-time 0s;
                transform: translateY(25%);
                opacity: 0;
            }
        }

        // om styling toe te passen adhv een active slide class
        /* stylelint-disable */
        &.is-selected {
            #{$elm} {
                &__inner {
                    margin-left: -20px;
                    margin-right: -20px;
                }

                &__title {
                    transition: all $transition-default-smoothing $transition-default-time #{$transition-default-time * 1.5};
                }

                &__description {
                    transition: all $transition-default-smoothing $transition-default-time #{$transition-default-time * 2};
                }

                &__title,
                &__description {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
        /* stylelint-enable */
    }

    &__image {
        width: 100%;
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
        text-align: center;
        margin-top: -0.5em;
    }

    &__function {
        color: map-deep-get($colors, "white", "default");
        text-align: center;
        display: block;
        font-weight: 100;
        margin-bottom: 1em;
    }

    &__description {
        color: map-deep-get($colors, "white", "default");
        text-align: center;
        font-weight: 100;
        -webkit-font-smoothing: antialiased;
    }

    &--page {
        padding: 20px 20px 40px;
        transition: $transition-default;

        #{$elm}{
            &__image {
                transition: $transition-default;
                margin-bottom: -30%;
                z-index: -1;
                position: relative;
            }

            &__description {
                transition: transform $transition-default-smoothing $transition-default-time $transition-default-time, opacity $transition-default-smoothing $transition-default-time 0s;
                transform: translateY(25%);
                opacity: 0;

                @media (pointer: coarse) {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }

        &:hover {
            #{$elm}__image {
                transform: scale(1.1);
            }

            #{$elm}__description {
                transition: transform $transition-default-smoothing $transition-default-time, opacity $transition-default-smoothing $transition-default-time #{$transition-default-time / 2};
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}