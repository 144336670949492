.contact-aside {
    margin-top: 40px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
        margin-top: 80px;
        margin-bottom: 120px;
    }

    &__address {
        margin-top: 40px;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            margin-top: 60px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 0;
        }
    }
}