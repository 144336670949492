.milestones-navigation {
    border-bottom: 1px solid map-deep-get($colors, "brand", "secondary");
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    z-index: 15;
    $nav: &;

    @include media-breakpoint-up(md) {
        left: 60px;
        width: 40%;
        right: initial;
    }

    @include media-breakpoint-up(lg) {
        left: 90px;
    }

    @include media-breakpoint-up(xl) {
        left: 120px;
        width: 30%;
    }

    &__year {
        display: inline-block;
        background-color: map-deep-get($colors, "brand", "secondary");
        color: rgba(map-deep-get($colors, "brand", "secondary"), 0.2);
        height: rem-calc(10);
        width: rem-calc(10);
        margin-bottom: -5px;
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(map-deep-get($colors, "brand", "primary"), .35);
        transition: $transition-default;
        font-weight: 900;
        cursor: pointer;

        // code vanuit de vendor
        /* stylelint-disable */
        left: auto !important;
        position: relative !important;
        /* stylelint-enable */

        #{$nav}__year-label {
            font-size: 14px;
            font-weight: 600;
            transform: rotate(-90deg);
            transform-origin: 8.97px 8.97px;
            padding-left: rem-calc(30);
            display: inline-block;
            position: absolute;
            left: 0;
            margin-left: -50%;
        }

        // code vanuit de vendor
        /* stylelint-disable */
        &.is-selected{
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "brand", "primary");
            box-shadow: 0 0 0 6px rgba(map-deep-get($colors, "brand", "primary"), .35);
        }
        /* stylelint-enable */
    }

    // code vanuit de vendor en js overschrijven
    /* stylelint-disable */
    .flickity-slider {
        transform: none !important;
        display: flex;
        justify-content: space-between;
    }

    .flickity-viewport {
        overflow: visible !important;
    }
    /* stylelint-enable */
}