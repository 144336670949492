.contact-item {
    margin-bottom: 1em;

    &__icon {
        width: rem-calc(30);
        height: rem-calc(30);
        margin-right: 1em;
        display: inline-block;
        vertical-align: middle;

        svg {
            width: 100%;
            height: 100%;
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__link {
        color: map-deep-get($colors, "black", "default");
    }
}