.contact {
    &__content{
        margin-top: 40px;
        background-color: map-deep-get($colors, "gray", "light");
        padding: calc(5% + 10px) 5% 5%;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }

        // webkey content
        /* stylelint-disable */
        h2 {
            margin-top: 0;
            margin-bottom: 0.5em;
            font-weight: 100;
        }
        /* stylelint-enable */

        .form__group:last-child {
            margin-bottom: 0;
        }
    }

    &__info-form {
        margin-top: 50px;

        @include media-breakpoint-up(md) {
            margin-top: -100px;
        }
    }
}