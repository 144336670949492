.team-slider {
    &__wrapper {
        position: relative;
        margin-top: 50px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    &__nav {
        display: inline-block;
        width: 50px;

        svg {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__nav-wrapper {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1500;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            left: 30%;
            right: 30%;
        }
    }
}