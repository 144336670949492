/***********
    Book
***********/

@font-face {
    font-family: CircularStd;
    src: url("../fonts/CircularStd-Book.eot");
    src: local("CircularStd-Book"),
        url("../fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-Book.woff2") format("woff2"),
        url("../fonts/CircularStd-Book.woff") format("woff"),
        url("../fonts/CircularStd-Book.ttf") format("truetype"),
        url("../fonts/CircularStd-Book.svg#CircularStd-Book") format("svg");
    font-weight: 300;
    font-style: normal;
}

/*****************
    Book italic
*****************/

@font-face {
    font-family: CircularStd;
    src: url("../fonts/CircularStd-BookItalic.eot");
    src: local("../fonts/CircularStd-BookItalic"),
        url("../fonts/CircularStd-BookItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-BookItalic.woff2") format("woff2"),
        url("../fonts/CircularStd-BookItalic.woff") format("woff"),
        url("../fonts/CircularStd-BookItalic.ttf") format("truetype"),
        url("../fonts/CircularStd-BookItalic.svg#CircularStd-BookItalic") format("svg");
    font-weight: 300;
    font-style: italic;
}

/*************
    normal
*************/

@font-face {
    font-family: CircularStd;
    src: url("../fonts/CircularStd-Medium.eot");
    src: local("CircularStd-Medium"),
        url("../fonts/CircularStd-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-Medium.woff2") format("woff2"),
        url("../fonts/CircularStd-Medium.woff") format("woff"),
        url("../fonts/CircularStd-Medium.ttf") format("truetype"),
        url("../fonts/CircularStd-Medium.svg#CircularStd-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
}

/*******************
    normal italic
*******************/

@font-face {
    font-family: CircularStd;
    src: url("../fonts/CircularStd-MediumItalic.eot");
    src: local("../fonts/CircularStd-MediumItalic"),
        url("../fonts/CircularStd-MediumItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-MediumItalic.woff2") format("woff2"),
        url("../fonts/CircularStd-MediumItalic.woff") format("woff"),
        url("../fonts/CircularStd-MediumItalic.ttf") format("truetype"),
        url("../fonts/CircularStd-MediumItalic.svg#CircularStd-MediumItalic") format("svg");
    font-weight: 500;
    font-style: italic;
}

/*************
    Bold
*************/

@font-face {
    font-family: CircularStd;
    src: url("../fonts/CircularStd-Bold.eot");
    src: local("CircularStd-Bold"),
        url("../fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-Bold.woff2") format("woff2"),
        url("../fonts/CircularStd-Bold.woff") format("woff"),
        url("../fonts/CircularStd-Bold.ttf") format("truetype"),
        url("../fonts/CircularStd-Bold.svg#CircularStd-Bold") format("svg");
    font-weight: 800;
    font-style: normal;
}

/*******************
    Bold italic
*******************/

@font-face {
    font-family: CircularStd;
    src: url("../fonts/CircularStd-BoldItalic.eot");
    src: local("CircularStd-BoldItalic"),
        url("../fonts/CircularStd-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-BoldItalic.woff2") format("woff2"),
        url("../fonts/CircularStd-BoldItalic.woff") format("woff"),
        url("../fonts/CircularStd-BoldItalic.ttf") format("truetype"),
        url("../fonts/CircularStd-BoldItalic.svg#CircularStd-BoldItalic") format("svg");
    font-weight: 800;
    font-style: italic;
}

/*************
    Black
*************/

@font-face {
    font-family: CircularStd;
    src: url("../fonts/CircularStd-Black.eot");
    src: local("CircularStd-Black"),
        url("../fonts/CircularStd-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-Black.woff2") format("woff2"),
        url("../fonts/CircularStd-Black.woff") format("woff"),
        url("../fonts/CircularStd-Black.ttf") format("truetype"),
        url("../fonts/CircularStd-Black.svg#CircularStd-Black") format("svg");
    font-weight: 900;
    font-style: normal;
}

/*******************
    Black italic
*******************/

@font-face {
    font-family: CircularStd;
    src: url("../fonts/CircularStd-BlackItalic.eot");
    src: local("CircularStd-BlackItalic"),
        url("../fonts/CircularStd-BlackItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-BlackItalic.woff2") format("woff2"),
        url("../fonts/CircularStd-BlackItalic.woff") format("woff"),
        url("../fonts/CircularStd-BlackItalic.ttf") format("truetype"),
        url("../fonts/CircularStd-BlackItalic.svg#CircularStd-BlackItalic") format("svg");
    font-weight: 900;
    font-style: italic;
}