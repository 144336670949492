.milestone-slide {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transition: $transition-default;
    width: 100%;
    $slide: &;

    &__content {
        opacity: 0;
        transition: all $transition-default-time $transition-default-time $transition-default-smoothing;
        display: inline-block;
        padding: 75px 25px 85px;
        width: 100%;
        order: 2;
        z-index: 1;

        @include media-breakpoint-up(md) {
            padding-top: 75px;
            padding-right: 25%;
            padding-left: 60px;
            order: 1;
            width: 75%;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 130px;
            padding-right: 30%;
            padding-left: 90px;
            order: 1;
            width: 75%;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 115px;
        }
    }

    &__title {
        color: rgba(map-deep-get($colors, "brand", "secondary"), 0.1);
        font-size: 75px;
        font-weight: 900;
        z-index: -1;
        position: relative;
        margin-bottom: -0.5em;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(100);
        }
    }

    &__description {
        line-height: 1.5em;
        margin-bottom: 2rem;
    }

    &__image {
        display: inline-block;
        opacity: 0;
        transform: translate(-25%, 0);
        width: 96%;
        order: 1;
        margin: 0 auto -50px;
        //padding-top: 75px;

        @include media-breakpoint-up(md) {
            margin-top: 75px;
            margin-bottom: -50%;
            padding-top: 35px;
            transform: translate(0, 25%);
            order: 2;
            margin-left: -20%;
            width: 42%;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 75px;
        }

        @include media-breakpoint-up(xl) {
            //margin-top: 80px;
            //margin-bottom: 80px;
            margin-left: -25%;
            width: 45%;
        }
    }

    // vendor classnames en js overschrijving
    /* stylelint-disable */
    left: 0 !important;
    &.is-selected {
        opacity: 1;
        z-index: 2;

        #{$slide} {
            &__image {
                transition: all $transition-default-time #{$transition-default-time * 2} $transition-default-smoothing;
                opacity: 1;
                transform: translate(0, 0);
            }

            &__content {
                opacity: 1;
            }
        }
    }
    /* stylelint-enalbe */
}