.system-pointers {
    position: relative;
    margin-top: 70px;
    $elem: &;

    &__image {
        position: relative;
        width: 100%;
        height: auto;
        display: block;

        &:not(.b-loaded) {
            padding-top: 49.95%;
        }
    }

    &__list {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}