.inspiration-item {
    position: relative;
    overflow: hidden;
    margin-bottom: $grid-gutter-width;

    $elm: &;

    &__image {
        width: 100%;
        vertical-align: middle;
    }

    &__overlay {
        background: linear-gradient(180deg, rgba(138, 172, 50, 0.33) 0%, map-deep-get($colors, "brand", "secondary") 100%);
        bottom: 0;
        color: map-deep-get($colors, "white", "default");
        display: flex;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity $transition-default-smoothing $transition-default-time;

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            opacity: 0;
        }
    }

    &__content {
        align-items: flex-end;
        display: flex;
        height: 100%;
        padding: 10px;
        transition: transform $transition-default-smoothing 0s #{$transition-default-time * 1.1}, opacity $transition-default-smoothing $transition-default-time;
        width: 100%;

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            opacity: 0;
            transform: translateY(-25%);
        }

        svg {
            width: 30px;
            height: 20px;
            fill: map-deep-get($colors, "brand", "primary");
            margin-right: 5px;
            vertical-align: text-top;
        }
    }

    &__link {
        text-decoration: underline;
        flex: 1;
    }

    &:hover {
        #{$elm}__overlay {
            opacity: 1;
        }

        #{$elm}__content {
            transform: translateY(0);
            opacity: 1;
            transition: transform $transition-default-smoothing $transition-default-time #{$transition-default-time * 0.4}, opacity $transition-default-smoothing $transition-default-time #{$transition-default-time * 0.5};
        }
    }
}