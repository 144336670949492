.warehouse-slider {
    position: relative;
    margin-left: -#{$grid-gutter-width / 2};
    margin-right: -#{$grid-gutter-width / 2};

    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }

    &__wrapper {
        position: relative;
    }

    &__nav-wrapper {
        color: map-deep-get($colors, "white", "default");
        font-weight: 300;
        -webkit-font-smoothing: antialiased;

        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
        }
    }

    &__nav {
        transition: $transition-default;
        display: inline-block;
        padding: 10px;
        border: 1px solid map-deep-get($colors, "white", "default");
        margin-left: 5px;
        line-height: 0;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "white", "default");
            width: 35px;
            max-height: 20px;
        }

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background: map-deep-get($colors, "white", "default");

                svg {
                    fill: map-deep-get($colors, "brand", "primary");
                }
            }
        }
    }

    &__inner {
        flex-flow: column nowrap;
        height: 100%;
        display: flex;
    }

    &__content {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-left: #{$grid-gutter-width / 2};
        padding-right: #{$grid-gutter-width / 2};
    }

    &__controls {
        pointer-events: all;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        align-content: flex-end;
    }
}