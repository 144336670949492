.variants {
    &__content {
        display: flex;
        width: 100%;
        flex: 1 1 100%;
        align-content: center;
        flex-wrap: wrap;
        margin-bottom: $grid-gutter-width;

        @include media-breakpoint-up(md) {
            height: 100%;
            padding-right: $grid-gutter-width;
        }
    }

    &__title {
        color: map-deep-get($colors, "brand", "secondary");
        margin-bottom: 1.5em;
    }
}