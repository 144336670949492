.warehouse-inspiration {
    &__title {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
        }
    }

    &__more {
        margin-bottom: 20px;
        display: inline-block;

        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
            display: inline-flex;
        }
    }
}

.section--inspiration {
    margin-bottom: rem-calc(100);
    margin-top: rem-calc(15);
}