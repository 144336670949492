.hero {
    height: 100%;
    margin-top: -#{map-get($header-height, "small")};

    @include media-breakpoint-up(md) {
        margin-top: -#{40px + map-get($header-height, "medium")};
    }

    @include media-breakpoint-up(lg) {
        margin-top: -#{40px + map-get($header-height, "large")};
    }

    &__scroll-down {
        margin-bottom: 20px;
        color: map-deep-get($colors, "white", "default");
        display: flex;
        align-items: center;
        white-space: nowrap;

        svg {
            transition: $transition-default;
            display: inline-block;
            height: 1.75em;
            width: 1em;
            fill: map-deep-get($colors, "white", "default");
            margin-right: 1em;
        }

        &:hover {
            svg {
                transform: translateY(20%);
            }
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
    }
}

.fetchimage-wrapper {
    width: 100%;
    height: 30vw;
    position: relative;
    background: map-deep-get($colors, "gray", "light");
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
        height: 25vw;
        margin-bottom: 100px;
    }
}