.dealer-cta {
    background-color: map-deep-get($colors, "gray", "light");
    padding-bottom: 5%;

    &__visual {
        margin-top: -39%;
        margin-bottom: 4%;
        width: 100%;
        height: 0;
        padding-top: 56%;
        position: relative;

        /* stylelint-disable */
        svg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            .st0 {
                fill: map-deep-get($colors, "brand", "secondary");
            }
        }
        /* stylelint-enable */
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
    }
}