.navigation {
    $elm: &;
    padding: 20px 0;
    display: flex;
    height: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        padding: 0;
        margin-left: 10px;
        height: auto;
        display: inline-block;
        flex-wrap: unset;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 30px;
    }

    &__wrapper {
        transition: all ease-in-out 0s 1s;
        height: 0;
        overflow: hidden;
        display: flex;

        @include media-breakpoint-up(md) {
            height: auto;
            overflow: initial;
        }

        &--active {
            transition: all ease-in-out 0s 0s;
            margin-top: -#{map-get($header-height, "small")};
            padding-top: #{map-get($header-height, "small")};
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }

        &--sub {
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        opacity: 0;
        display: block;
        position: relative;
        padding: 10px 0;

        @include media-breakpoint-up(md) {
            padding: 5px 8px;
            opacity: 1;
            display: inline-block;
        }

        &--has-sub:hover #{$elm}__list--sub {
            display: block;
        }
    }

    &__item {
        display: block;
        text-align: center;
        font-size: rem-calc(16);
        font-weight: 900;
        line-height: 1.25em;
        color: map-deep-get($colors, "brand", "secondary");

        @include media-breakpoint-up(md) {
            &::before {
                content: "";
                transition: $transition-default;
                opacity: 0;
                position: absolute;
                bottom: 0;
                width: 18px;
                margin-left: -9px;
                left: 50%;
                height: 0;
                background: map-deep-get($colors, "brand", "primary");
                border-radius: 9px 9px 0 0;
            }
        }

        @include media-breakpoint-up(md) {
            text-align: unset;
        }

        &:hover {
            color: map-deep-get($colors, "brand", "primary");
        }

        &--active {
            color: map-deep-get($colors, "brand", "primary");

            &::before {
                opacity: 1;
                bottom: 100%;
                width: 18px;
                height: 9px;
            }
        }
    }

    &--active {
        #{$elm}__list-item {
            opacity: 1;
            // todo aanpassen aan het design
            padding: 5px 0;
        }
    }
}