.page {
    $elm: &;

    &__title {
        margin-top: 0.35em;
        margin-bottom: 0.5em;

        &--contact {
            @include media-breakpoint-up(md) {
                margin-bottom: 1em;
            }
        }
    }
}