﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1, .h1 {
    font-size: rem-calc(50);
    font-weight: 900;
    line-height: 0.9em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(75);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(100);
    }
}

h2, .h2 {
    font-size: rem-calc(40);
    font-weight: 300;
    line-height: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(60);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(80);
    }
}

h3, .h3 {
    font-size: rem-calc(35);
    font-weight: 800;
    line-height: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(45);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(60);
    }
}

h4, .h4 {
    font-size: rem-calc(30);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(35);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(40);
    }
}

h5, .h5 {
    font-size: rem-calc(26);
    font-weight: 300;
    line-height: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(26);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(26);
    }
}

h6, .h6 {
    font-size: rem-calc(20);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;
}