.partners-item {
    background-color: map-deep-get($colors, "brand", "secondary");
    height: 100%;
    width: 100%;
    text-align: center;
    position: relative;
    padding: 10px;

    @include media-breakpoint-up(md) {
        padding: 15px;
    }

    @include media-breakpoint-up(xl) {
        padding: 25px;
    }

    &__wrapper {
        padding-bottom: $grid-gutter-width;
        height: 100%;

        @include media-breakpoint-up(md) {
            padding-bottom: 65px;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 80px;
        }
    }

    &__number {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "brand", "primary");
        font-size: rem-calc(20);
        font-weight: 500;
        margin-bottom: 0.25em;
    }

    &__title {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "white", "default");
        font-weight: 300;
        font-size: rem-calc(16);
        margin-bottom: 2.25em;
    }

    &__description {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "white", "default");
        font-size: rem-calc(14);
        font-weight: 600;
        letter-spacing: 0.05em;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(16);
        }

        @include media-breakpoint-up(xl) {
            font-size: rem-calc(20);
            font-weight: 900;
        }
    }

    &__arrow {
        position: absolute;
        width: 18px;
        height: 18px;
        display: flex;

        @include media-breakpoint-up(md) {
            width: 38px;
            height: 38px;
        }

        &--down {
            left: 50%;
            margin-top: 1px;
            top: 100%;
            transform: translateX(-50%);

            @include media-breakpoint-up(md) {
                margin-top: 14px;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 20px;
            }
        }

        &--right {
            left: 100%;
            margin-left: 1px;
            top: 50%;
            transform: translateY(-50%);

            @include media-breakpoint-up(md) {
                margin-left: 14px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 20px;
            }
        }

        svg {
            fill: map-deep-get($colors, "brand", "primary");
            width: 100%;
            height: 100%;
        }
    }
}