.highlighted-slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__wrapper {
        position: relative;
        height: 100%;
    }

    &__nav-wrapper {
        display: inline-flex;
        align-items: center;
        margin-bottom: 20px;
        color: map-deep-get($colors, "white", "default");
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
    }

    &__nav {
        transition: $transition-default;
        display: inline-block;
        padding: 10px;
        border: 1px solid map-deep-get($colors, "gray", "dark");
        margin-left: 5px;
        line-height: 0;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "white", "default");
            width: 35px;
            max-height: 20px;
        }

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background: map-deep-get($colors, "white", "default");
                border-color: map-deep-get($colors, "white", "default");

                svg {
                    fill: map-deep-get($colors, "brand", "primary");
                }
            }
        }
    }

    &__inner {
        flex-flow: column nowrap;
        height: 100%;
        display: flex;
        padding-top: map-get($header-height, "small");

        @include media-breakpoint-up(md) {
            padding-top: map-get($header-height, "medium");
        }

        @include media-breakpoint-up(xl) {
            padding-top: map-get($header-height, "large");
        }
    }

    &__content {
        color: map-deep-get($colors, "white", "default");
        font-size: rem-calc(20);
        height: 100%;
    }

    &__controls {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
    }

    &__controls-wrapper {
        margin: 20px -5px;

        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }

    /* stylelint-disable */
        .flickity-viewport,
        .flickity-slider {
            height: 100% !important;
        }
    /* stylelint-enable */

    &__usps {
        display: flex;
        row-gap: 1.875rem;
    }

    &__usp {
        display: flex;
        gap: 0.75rem;

        span {
            color: map-deep-get($colors, "brand", "primary");
            font-size: 1.5rem;
            font-weight: 700;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        font-size: .875rem;
        font-weight: 300;
        gap: .25rem;
    }

    &__icon {
        line-height: 2.5rem;
    }
}
