.variant {
    padding-bottom: $grid-gutter-width;
    width: 100%;
    $elm: &;

    @include media-breakpoint-up(sm) {
        height: 100%;
    }

    &__inner {
        height: 100%;
        background-size: cover;
        background-position: center;
        background-color: map-deep-get($colors, "brand", "secondary");
        padding: 20px;
        position: relative;
        overflow: hidden;

        @media (pointer: coarse) {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        @include media-breakpoint-up(md) {
            padding: 40px 20px 20px;
        }

        @include media-breakpoint-up(md) {
            padding: 60px 20px 20px;
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        height: 300px;
        align-content: flex-end;
    }

    &__image-wrapper {
        flex: 0 0 80%;
        max-width: 80%;
        margin-left: 10%;
    }

    &__image {
        width: 100%;

        svg {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__height {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: right;
        color: map-deep-get($colors, "white", "default");
        margin-bottom: 10px;
        font-size: rem-calc(20);

        @include media-breakpoint-up(md) {
            margin-bottom: 25px;
        }
    }

    &__height-arrow-wrapper {
        flex: 0 0 10%;
        max-width: 10%;
        padding-left: 10px;
        text-align: center;
    }

    &__height-arrow {
        height: 90%;
        width: 1px;
        background-color: map-deep-get($colors, "brand", "primary");
        display: inline-block;
        position: relative;
    }

    &__height-arrowhead {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -6px;
        width: 12px;
        height: 12px;
        border-top: 1px solid map-deep-get($colors, "brand", "primary");
        border-left: 1px solid map-deep-get($colors, "brand", "primary");

        &--top {
            top: 1px;
            transform: rotate(45deg);
        }

        &--bottom {
            bottom: 1px;
            transform: rotate(225deg);
        }
    }

    &__width {
        flex: 0 0 80%;
        max-width: 80%;
        text-align: center;
        color: map-deep-get($colors, "white", "default");
        font-size: rem-calc(20);
        margin-left: 10%;
    }

    &__width-arrow-wrapper {
        width: 100%;
    }

    &__width-arrow {
        width: 85%;
        height: 1px;
        background-color: map-deep-get($colors, "brand", "primary");
        display: inline-block;
        position: relative;
    }

    &__width-arrowhead {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        border-top: 1px solid map-deep-get($colors, "brand", "primary");
        border-left: 1px solid map-deep-get($colors, "brand", "primary");

        &--left {
            left: 1px;
            transform: rotate(-45deg);
        }

        &--right {
            right: 1px;
            transform: rotate(-225deg);
        }
    }

    &__title {
        flex: 0 0 100%;
        max-width: 100%;
        font-weight: 300;
        font-size: rem-calc(20);
        text-align: center;
        color: map-deep-get($colors, "white", "default");
        margin-top: 10px;
        position: relative;
        z-index: 10;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
            font-size: rem-calc(26);
        }

        @include media-breakpoint-up(lg) {
            margin-top: 50px;
        }
    }

    &__overlay {
        background: rgba(map-deep-get($colors, "brand", "primary"), 0.9);
        padding: 40px;
        transition: $transition-default;
        margin: 20px -20px -20px;

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            margin: 0;
            transform: translateY(-100%);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            text-align: center;
        }

        @media (pointer: coarse) {
            padding: 0;

            /* stylelint-disable */
                .button {
                    background-color: map-deep-get($colors, "brand", "primary");
                    width: 100%;
                    display: block;
                }
            /* stylelint-enable */
        }
    }

    &:hover {
        #{$elm}__overlay {
            transform: translateY(0);
        }
    }
}
