.inspiration {
    $elm: &;

    &__share {
        display: flex;
        align-content: center;

        svg {
            width: 37px;
            height: 23px;
            //display: inline-block;
            display: none;
            fill: map-deep-get($colors, "brand", "primary");
            margin-right: 10px;
        }
    }


    &__title {
        font-size: rem-calc(30);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(40);
        }
    }

    &__share-icon {
        margin-left: 12px;
        height: 22px;
        width: 22px;

        @include media-breakpoint-up(lg) {
            height: 33px;
            width: 33px;
        }

        &--twitter {
            width: 30px;

            @include media-breakpoint-up(lg) {
                width: 45px;
            }
        }

        svg {
            transition: $transition-default;
            display: inline-block;
            fill: map-deep-get($colors, "brand", "primary");
            height: 100%;
            width: 100%;
        }

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                svg {
                    fill: map-deep-get($colors, "brand", "secondary");
                }
            }
        }
    }

    &__share-link {
        flex: 1;
        align-self: center;
    }

    &__close-icon {
        transition: $transition-default;
        display: inline-block;
        border: 1px solid map-deep-get($colors, "gray", "default");
        margin-left: 10px;
        line-height: 0;
        width: 45px;
        height: 45px;
        padding: 10px;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "brand", "primary");
            width: 23px;
            height: 23px;
        }
    }

    &__close {
        color: map-deep-get($colors, "black", "default");
        text-decoration: underline;
        display: flex;
        align-items: center;

        &:hover {
            #{$elm}__close-icon {
                background: map-deep-get($colors, "brand", "primary");
            }

            #{$elm}__close-icon svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }

    &__navigation-item {
        padding: 0.5em 0.75em;
        line-height: 1.25em;
        font-size: rem-calc(13);
        border-radius: 0;
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");
        border: 1px solid transparent;
        transition: $transition-default;
        font-weight: 900;
        text-align: center;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            padding: 0.75em 1.25em;
            min-height: rem-calc(50);
            font-size: rem-calc(16);
        }

        @include media-breakpoint-up(lg) {
            position: fixed;
            top: 50%;
        }

        svg {
            width: rem-calc(20);
            height: rem-calc(20);
            vertical-align: middle;
        }
    }

    &__navigation-item__title {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__navigation-item--prev {
        @include media-breakpoint-up(lg) {
            left: 30px;
        }

        svg {
            margin-left: rem-calc(-8);
            margin-right: rem-calc(8);
        }
    }

    &__navigation-item--next {
        float: right;
        @include media-breakpoint-up(lg) {
            right: 30px;
            float: none;
        }

        svg {
            margin-left: rem-calc(8);
            margin-right: rem-calc(-8);
        }
    }

    &__navigation {
        background-color: map-deep-get($colors, "white", "default");
        position: sticky;
        bottom: 0;
        align-self: flex-end;
        left: 0;
        right: 0;
    }
}
