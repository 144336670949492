.team {
    background: map-deep-get($colors, "brand", "secondary");
    padding-bottom: 50px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        padding-bottom: 100px;
    }

    &__title {
        @extend .h1;
        text-align: center;
        position: relative;
        z-index: 1;
        color: map-deep-get($colors, "brand", "primary");
        margin-top: -1em;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    &__roof {
        line-height: 0;
        width: 100%;
        position: relative;
        height: 0;
        padding-bottom: 17.5%;
        background-color: map-deep-get($colors, "brand", "secondary");

        svg {
            position: absolute;
            left: 0;
            top: -5%;
            height: 100%;
            width: 100%;

            /* stylelint-disable */
            .st0 {
                fill: map-deep-get($colors, "white", "default")
            }
            /* stylelint-enable */
        }
    }

    &__intro {
        color: map-deep-get($colors, "white", "default");
        margin-bottom: 100px;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        line-height: 1.5em;
    }

    &__slider {
        @include media-breakpoint-up(md) {
            margin-left: -50px;
            margin-right: -50px;
        }

        &--ready {
            /* stylelint-disable */
            .team-member__inner {
                padding-left: 50px;
                padding-right: 50px;
            }

            .team-member:not(.is-selected) .team-member__inner {
                margin-left: 0;
                margin-right: 0;
            }
            /* stylelint-enable */
        }
    }

    &--page {
        padding-left: 20px;
        padding-right: 20px;
    }
}