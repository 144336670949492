.section--colors {
    margin-bottom: rem-calc(100);
    margin-top: rem-calc(15);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(120);
        margin-top: rem-calc(30);
    }
}

.section--warehouse__images {
    margin-top: rem-calc(15);
    margin-bottom: rem-calc(50);

    @include media-breakpoint-up(md) {
        margin-top: rem-calc(30);
    }
}

.images-wrapper {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
}
