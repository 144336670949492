.warehouses-overview{
    margin-top: 50px;

    @include media-breakpoint-up(lg) {
        margin-top: 130px;
    }

    &__header {
        text-align: center;
        margin-bottom: 3rem;

        p {
            font-weight: 300;
        }
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
        margin-bottom: .75rem;
    }
}
