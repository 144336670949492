.highlighted-slide {
    width: 100%;
    height: 100%;

    &__image {
        background-color: map-deep-get($colors, "brand", "secondary");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &::before {
            background-color: rgba(map-deep-get($colors, "brand", "secondary"), 0.5);
            z-index: 5;
        }

        &::after {
            background: linear-gradient(180deg, map-deep-get($colors, "white", "default") 0%, rgba(map-deep-get($colors, "white", "default"), 0) 70%);
            opacity: 0.75;
            z-index: 10;
            bottom: auto;
            height: 30%;
        }
    }

    &__counter {
        display: none;
        margin-left: 10px;

        @include media-breakpoint-up(md) {
            display: inline-block;
            margin-right: 10px;
        }

        @include media-breakpoint-up(xl) {
            margin-right: 20px;
        }
    }

    &__link {
        display: block;
        color: inherit;
        text-decoration: underline;
        font-weight: 900;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            margin-right: 10px;
        }

        @include media-breakpoint-up(xl) {
            margin-right: 20px;
        }
    }
}