.section--highlighted {
    /* stylelint-disable */
    @media (min-height: 520px) {
        height: 1px;
    }

    @include media-breakpoint-up(md) {
        height: 100vh;
    }
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    /* stylelint-enable */
}