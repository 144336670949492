.footer {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    position: relative;
    z-index: 15;
    padding-top: 30px;
    padding-bottom: 30px;

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: map-deep-get($colors, "black", "default");
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-block;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-left: 8px;
            padding-right: 8px;
            border-left: 1px solid map-deep-get($colors, "black", "default");

            &:first-child {
                border-left: none;
            }
        }
    }

    &__item {
        color: inherit;
    }

    &__reference {
        font-size: 14px;
        font-weight: 300;
        color: rgba(map-deep-get($colors, "black", "default"), 0.3);
        display: block;
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: inline-block;
            text-align: left;
        }
    }

    &__webnl {
        transition: $transition-default;
        color: inherit;

        &:hover {
            color: map-deep-get($colors, "black", "default");
        }
    }
}